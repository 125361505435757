var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Main',
        },
        {
          title: 'Widgets',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" We used Bangalore Illustration Set and customized it for Yoda ")])]},proxy:true}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('illustration-item',{attrs:{"propsIllustrations":_vm.illustrations}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }